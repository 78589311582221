// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-almanac-js": () => import("./../../../src/pages/almanac.js" /* webpackChunkName: "component---src-pages-almanac-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooperation-js": () => import("./../../../src/pages/cooperation.js" /* webpackChunkName: "component---src-pages-cooperation-js" */),
  "component---src-pages-education-2020-js": () => import("./../../../src/pages/education/2020.js" /* webpackChunkName: "component---src-pages-education-2020-js" */),
  "component---src-pages-education-2021-js": () => import("./../../../src/pages/education/2021.js" /* webpackChunkName: "component---src-pages-education-2021-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-almanac-js": () => import("./../../../src/pages/en/almanac.js" /* webpackChunkName: "component---src-pages-en-almanac-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-cooperation-js": () => import("./../../../src/pages/en/cooperation.js" /* webpackChunkName: "component---src-pages-en-cooperation-js" */),
  "component---src-pages-en-education-js": () => import("./../../../src/pages/en/education.js" /* webpackChunkName: "component---src-pages-en-education-js" */),
  "component---src-pages-en-festivals-12-festival-about-js": () => import("./../../../src/pages/en/festivals/12-festival/about.js" /* webpackChunkName: "component---src-pages-en-festivals-12-festival-about-js" */),
  "component---src-pages-en-festivals-12-festival-contact-js": () => import("./../../../src/pages/en/festivals/12-festival/contact.js" /* webpackChunkName: "component---src-pages-en-festivals-12-festival-contact-js" */),
  "component---src-pages-en-festivals-12-festival-cooperation-js": () => import("./../../../src/pages/en/festivals/12-festival/cooperation.js" /* webpackChunkName: "component---src-pages-en-festivals-12-festival-cooperation-js" */),
  "component---src-pages-en-festivals-12-festival-index-js": () => import("./../../../src/pages/en/festivals/12-festival/index.js" /* webpackChunkName: "component---src-pages-en-festivals-12-festival-index-js" */),
  "component---src-pages-en-festivals-13-festival-about-js": () => import("./../../../src/pages/en/festivals/13-festival/about.js" /* webpackChunkName: "component---src-pages-en-festivals-13-festival-about-js" */),
  "component---src-pages-en-festivals-13-festival-contact-js": () => import("./../../../src/pages/en/festivals/13-festival/contact.js" /* webpackChunkName: "component---src-pages-en-festivals-13-festival-contact-js" */),
  "component---src-pages-en-festivals-13-festival-dispersed-festival-2023-js": () => import("./../../../src/pages/en/festivals/13-festival/dispersed-festival-2023.js" /* webpackChunkName: "component---src-pages-en-festivals-13-festival-dispersed-festival-2023-js" */),
  "component---src-pages-en-festivals-13-festival-index-js": () => import("./../../../src/pages/en/festivals/13-festival/index.js" /* webpackChunkName: "component---src-pages-en-festivals-13-festival-index-js" */),
  "component---src-pages-en-festivals-14-festival-index-js": () => import("./../../../src/pages/en/festivals/14-festival/index.js" /* webpackChunkName: "component---src-pages-en-festivals-14-festival-index-js" */),
  "component---src-pages-en-festivals-js": () => import("./../../../src/pages/en/festivals.js" /* webpackChunkName: "component---src-pages-en-festivals-js" */),
  "component---src-pages-en-festivals-kosmopolis-about-js": () => import("./../../../src/pages/en/festivals/kosmopolis/about.js" /* webpackChunkName: "component---src-pages-en-festivals-kosmopolis-about-js" */),
  "component---src-pages-en-festivals-kosmopolis-contact-js": () => import("./../../../src/pages/en/festivals/kosmopolis/contact.js" /* webpackChunkName: "component---src-pages-en-festivals-kosmopolis-contact-js" */),
  "component---src-pages-en-festivals-kosmopolis-cooperation-js": () => import("./../../../src/pages/en/festivals/kosmopolis/cooperation.js" /* webpackChunkName: "component---src-pages-en-festivals-kosmopolis-cooperation-js" */),
  "component---src-pages-en-festivals-kosmopolis-index-js": () => import("./../../../src/pages/en/festivals/kosmopolis/index.js" /* webpackChunkName: "component---src-pages-en-festivals-kosmopolis-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-20230909-js": () => import("./../../../src/pages/en/news/20230909.js" /* webpackChunkName: "component---src-pages-en-news-20230909-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-research-js": () => import("./../../../src/pages/en/research.js" /* webpackChunkName: "component---src-pages-en-research-js" */),
  "component---src-pages-festivals-12-festival-about-js": () => import("./../../../src/pages/festivals/12-festival/about.js" /* webpackChunkName: "component---src-pages-festivals-12-festival-about-js" */),
  "component---src-pages-festivals-12-festival-contact-js": () => import("./../../../src/pages/festivals/12-festival/contact.js" /* webpackChunkName: "component---src-pages-festivals-12-festival-contact-js" */),
  "component---src-pages-festivals-12-festival-cooperation-js": () => import("./../../../src/pages/festivals/12-festival/cooperation.js" /* webpackChunkName: "component---src-pages-festivals-12-festival-cooperation-js" */),
  "component---src-pages-festivals-12-festival-index-js": () => import("./../../../src/pages/festivals/12-festival/index.js" /* webpackChunkName: "component---src-pages-festivals-12-festival-index-js" */),
  "component---src-pages-festivals-13-festival-about-js": () => import("./../../../src/pages/festivals/13-festival/about.js" /* webpackChunkName: "component---src-pages-festivals-13-festival-about-js" */),
  "component---src-pages-festivals-13-festival-contact-js": () => import("./../../../src/pages/festivals/13-festival/contact.js" /* webpackChunkName: "component---src-pages-festivals-13-festival-contact-js" */),
  "component---src-pages-festivals-13-festival-dispersed-festival-2023-js": () => import("./../../../src/pages/festivals/13-festival/dispersed-festival-2023.js" /* webpackChunkName: "component---src-pages-festivals-13-festival-dispersed-festival-2023-js" */),
  "component---src-pages-festivals-13-festival-index-js": () => import("./../../../src/pages/festivals/13-festival/index.js" /* webpackChunkName: "component---src-pages-festivals-13-festival-index-js" */),
  "component---src-pages-festivals-14-festival-about-js": () => import("./../../../src/pages/festivals/14-festival/about.js" /* webpackChunkName: "component---src-pages-festivals-14-festival-about-js" */),
  "component---src-pages-festivals-14-festival-contact-js": () => import("./../../../src/pages/festivals/14-festival/contact.js" /* webpackChunkName: "component---src-pages-festivals-14-festival-contact-js" */),
  "component---src-pages-festivals-14-festival-index-js": () => import("./../../../src/pages/festivals/14-festival/index.js" /* webpackChunkName: "component---src-pages-festivals-14-festival-index-js" */),
  "component---src-pages-festivals-js": () => import("./../../../src/pages/festivals.js" /* webpackChunkName: "component---src-pages-festivals-js" */),
  "component---src-pages-festivals-kosmopolis-about-js": () => import("./../../../src/pages/festivals/kosmopolis/about.js" /* webpackChunkName: "component---src-pages-festivals-kosmopolis-about-js" */),
  "component---src-pages-festivals-kosmopolis-contact-js": () => import("./../../../src/pages/festivals/kosmopolis/contact.js" /* webpackChunkName: "component---src-pages-festivals-kosmopolis-contact-js" */),
  "component---src-pages-festivals-kosmopolis-cooperation-js": () => import("./../../../src/pages/festivals/kosmopolis/cooperation.js" /* webpackChunkName: "component---src-pages-festivals-kosmopolis-cooperation-js" */),
  "component---src-pages-festivals-kosmopolis-index-js": () => import("./../../../src/pages/festivals/kosmopolis/index.js" /* webpackChunkName: "component---src-pages-festivals-kosmopolis-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-20230909-js": () => import("./../../../src/pages/news/20230909.js" /* webpackChunkName: "component---src-pages-news-20230909-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-templates-edu-page-js": () => import("./../../../src/templates/edu-page.js" /* webpackChunkName: "component---src-templates-edu-page-js" */),
  "component---src-templates-fest-12-page-js": () => import("./../../../src/templates/fest12-page.js" /* webpackChunkName: "component---src-templates-fest-12-page-js" */),
  "component---src-templates-fest-13-page-js": () => import("./../../../src/templates/fest13-page.js" /* webpackChunkName: "component---src-templates-fest-13-page-js" */),
  "component---src-templates-fest-14-page-js": () => import("./../../../src/templates/fest14-page.js" /* webpackChunkName: "component---src-templates-fest-14-page-js" */),
  "component---src-templates-kosmopolis-page-js": () => import("./../../../src/templates/kosmopolis-page.js" /* webpackChunkName: "component---src-templates-kosmopolis-page-js" */)
}

